import * as Yup from 'yup'
import { TELEPHONE_VALIDATION } from '@utils/mask'

export const testCRECI = (value, profileType) => {
  if (!value) return false
  const [digits, maskEnd] = value.split('-')
  if (!digits || !maskEnd) return false
  if (digits.length === 0 || maskEnd.trim() !== profileType) return false
  return true
}

export const getProfileTypeInfo = profileType => {
  if (profileType === 'realEstateAgency') {
    return {
      label: 'Pessoa Jurídica',
      maskEnd: 'PJ',
    }
  }
  return {
    label: 'Pessoa Física',
    maskEnd: 'PF',
  }
}

export const SignupSchema = Yup.object().shape({
  ProfileType: Yup.string().required('Campo obrigatório'),
  Email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Campo obrigatório'),
  City: Yup.string().required('Campo obrigatório'),
  CompanyName: Yup.string().when('ProfileType', {
    is: 'realEstateAgency',
    then: Yup.string().test(
      'isValid',
      'Campo obrigatório',
      value => value && value.length > 0,
    ),
  }),
  CRECI: Yup.string()
    .required('Obrigatório informar nº CRECI')
    .max(10, 'Máximo 10 caracteres'),
  Name: Yup.string()
    .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Insira o nome completo.')
    .required('Campo obrigatório'),
  PhoneNumber: Yup.string()
    .matches(TELEPHONE_VALIDATION, 'Telefone inválido')
    .required('Obrigatório'),
  Password: Yup.string()
    .required('Campo obrigatório')
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  HasValidCity: Yup.bool().oneOf([true], 'Cidade inválida'),
})

export const initialValues = {
  ProfileType: '',
  Email: '',
  City: '',
  CRECI: '',
  Name: '',
  PhoneNumber: '',
  Password: '',
  CompanyName: '',
  HasValidCity: false,
}

export const formatPayload = (data, cityId) => {
  const { Email, Name, Password, PhoneNumber, CRECI, ProfileType } = data
  if (ProfileType === 'realEstateAgency')
    return {
      name: Name,
      email: Email,
      password: Password,
      confirmPassword: Password,
      phone: PhoneNumber,
      city: cityId,
      Creci: CRECI,
      company: data.CompanyName,
    }
  return {
    name: Name,
    email: Email,
    password: Password,
    confirmPassword: Password,
    phone: PhoneNumber,
    city: cityId,
    Creci: CRECI,
    company: data.CompanyName ?? 'Corretor',
  }
}

export const formatLeadPayload = (formData, leadData) => {
  const {
    Email,
    Name,
    PhoneNumber,
    CRECI,
    ProfileType,
    City,
    CompanyName,
  } = formData

  const {
    cf_gclid,
    cf_fbclid,
    cf_utm_campaign,
    cf_utm_content,
    cf_utm_medium,
    cf_utm_source,
    cf_utm_term,
  } = leadData

  const [cityName, state] = City.split('-')
  const [firstName, lastName] = Name.split(' ')

  const isRealEstateAgency = ProfileType === 'realEstateAgency'

  return {
    contact: {
      email: Email,
      firstName,
      lastName,
      phone: PhoneNumber,
      fieldValues: [
        {
          field: '11', // Empresa
          value: CompanyName ?? firstName,
        },
        {
          field: '15', // Imobiliária / Corretor
          value: isRealEstateAgency ? 'Imobiliária' : 'Corretor',
        },
        {
          field: '23', // Celular
          value: PhoneNumber,
        },
        {
          field: '19', // Estado
          value: state,
        },
        {
          field: '18', // Cidade
          value: cityName,
        },
        {
          field: '20', // CRECI
          value: CRECI,
        },
        {
          field: '21', // FBCLID
          value: cf_fbclid ?? '',
        },
        {
          field: '14', // GCLID
          value: cf_gclid ?? '',
        },
        {
          field: '9', // UTM_TERM
          value: cf_utm_term ?? '',
        },
        {
          field: '6', // UTM_CAMPAIGN
          value: cf_utm_campaign ?? '',
        },
        {
          field: '4', // UTM_SOURCE
          value: cf_utm_source ?? '',
        },
        {
          field: '7', // UTM_MEDIUM
          value: cf_utm_medium ?? '',
        },
        {
          field: '8', // UTM_CONTENT
          value: cf_utm_content ?? '',
        },
        {
          field: '10', // Possui CRECI
          value: 'Sim',
        },
      ],
    },
    leadType: isRealEstateAgency ? 'real_estate' : 'realtor',
  }
}

export const removeParentheses = string => string.replace(/[{()}]/g, '')
